import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
export * from '@root/constants/events';

class Events {
  constructor() {
    this.$eventSubject = new Subject();
    if(!this.instance) this.instance = this;
    return this.instance;
  }

  trigger({type, data = null}) {
    this.$eventSubject.next({type, data});
  }

  $event(type) {
    return this.$eventSubject.asObservable().pipe(
      filter(event=>(event !== null && event.type === type)),
    );
  }
}
const EventsService = new Events;
export default EventsService;