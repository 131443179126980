import UserService from "./user";
import * as Sentry from "@sentry/nextjs";
import { forEach, get, isNull, map } from "lodash";
class Logger {
  constructor() {
    if(!this.instance) this.instance = this;
    return this.instance;
  }

  logError(message, data = {}) {
    let user = UserService.$behaviorUser.getValue();
    Sentry.captureException(new Error(message), {
      extra: data,
      user: this._userData(user),
      fingerprint: ['{{ default }}', message],
    })
  }

  _userData(user) {
    if(isNull(user)) return {
      email: '', 
      id: 'guest', 
      type: 'guest'
    };

    let id = get(user, ['id']);
    let email = get(user, ['email']);
    let lastname = get(user, ['lastname']);
    let firstname = get(user, ['firstname']);

    let attribObj = {};
    let attributes = get(user, ['custom_attributes'], []);
    forEach(attributes, attribute => {
      attribObj[attribute.attribute_code] = attribute.value
    })
    let mobile = get(attribObj, ['mobilenumber']);

    let addresses = get(user, ['addresses'], []);
    let stringfied = map(addresses, address => JSON.stringify(address));
    return {
      id: id,
      ...attribObj,
      email: email,
      mobile: mobile,
      type: 'registered',
      lastname: lastname,
      firstname: firstname,
      addresses: stringfied,
    }
  }
}
const LoggerService = new Logger;
export default LoggerService;