import {get} from 'lodash';
import {UAParser} from 'ua-parser-js';

class UserAgent {
  constructor() {
    this.cdevice = null;
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  device(string) {
    let parser = new UAParser(string);
    this.cdevice =
      get(parser.getResult(), ['device', 'type']) === 'mobile'
        ? 'mobile'
        : 'desktop';
    return this.cdevice;
  }
}

const UserAgentService = new UserAgent();
export default UserAgentService;
