import { isNull } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import PopModal from "@root/common/PopModal";
import LocaleService from "@root/services/locale";
import EventsService from "@root/services/events";
import Illustration from "@root/common/Illustration";
import { SESSION_TIMEOUT } from '@root/constants/events';

import styles from "./styles.module.scss";
import AuthService from "@root/services/auth";

function SessionTimeout() {
  const router = useRouter();
  const modalRef = useRef(null);
  const { t } = LocaleService.useLocale();

  useEffect(() => {
    let event_sub = EventsService.$event(SESSION_TIMEOUT).subscribe(() => {
      if(!isNull(modalRef.current)) {
        modalRef.current.show();
        AuthService.logout();
      }
    });

    return () => {
      event_sub.unsubscribe();
    }
  }, [])

  const onPressLogin = () => {
    modalRef.current.hide();
    router.push('/login');
  }
  
  return (
    <PopModal ref={modalRef} expanded>
      <div className={styles.sessionout}>
        <span className={styles.sessionout_illustration}>
          <Illustration
            onPress={onPressLogin}
            title={t("br_error_session_expired")}
            button={t("br_error_session_expired_login")}
            message={t("br_error_session_expired_login_again")}
            image={require("@root/assets/images/deadline-pana.png")}
          />
        </span>
      </div>
    </PopModal>
  );
}

export default SessionTimeout;
