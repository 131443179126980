import { BehaviorSubject } from "rxjs";
import { get, join, filter, indexOf, first, isNull } from "lodash";

class GoogleMap {
  constructor() {
    this.map = null;
    this.$isReady = new BehaviorSubject(false);
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  init() {
    this.$isReady.next(true);
  }

  generateMap(element, initial) {
    let center = new google.maps.LatLng(initial.lat, initial.long);
    this.map = new google.maps.Map(element, {
      center: center,
      zoom: initial.zoom,
      disableDefaultUI: true,
    });
  }

  listen(key, cb) {
    return this.map.addListener(key, cb);
  }

  clearListeners() {
    if(isNull(this.map)) return;
    google.maps.event.clearInstanceListeners(this.map);
  }

  getLocation() {
    return new Promise((resolvep, rejectp) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          resolvep(position);
        }, (error) => rejectp(error), {enableHighAccuracy: true})
      }
      else rejectp({message: 'Not supported'})
    });
  }

  reverseGeoCodeByLatLng(latlng) {
    let geocoder = new google.maps.Geocoder();
    return geocoder.geocode({ location: latlng });
  }

  placeDetail(id) {
    return new Promise((resolvep) => {
      let places = new google.maps.places.PlacesService(this.map);
      places.getDetails(
        {
          placeId: id,
          fields: ["name", "geometry", "address_components"],
          language: "en-GB",
        },
        (details) => {
          resolvep(details);
        }
      );
    });
  }

  autocomplete(keyword) {
    return new Promise((resolvep) => {
      let suggestions = new google.maps.places.AutocompleteService();
      suggestions.getPlacePredictions(
        {
          input: keyword,
          language: "en-GB",
          componentRestrictions: { country: "ae" },
        },
        (predictions) => {
          resolvep(predictions);
        }
      );
    });
  }

  getAddressType(flatlist, type) {
    let filtered = filter(flatlist, (item) => indexOf(item.types, type) > -1);
    return get(first(filtered), ["long_name"]);
  }

  parseAddress(data) {
    let name = get(data, ["name"]);
    let components = get(data, ["address_components"]);
    let location = get(data, ["geometry", "location"]);

    let premise = this.getAddressType(components, "premise");
    let route = this.getAddressType(components, "route");
    let neighborhood = this.getAddressType(components, "neighborhood");
    let sublocality = this.getAddressType(components, "sublocality");
    let locality = this.getAddressType(components, "locality");
    let country = this.getAddressType(components, "country");

    let area = [];
    if (route) area.push(route);
    if (neighborhood) area.push(neighborhood);
    if (sublocality) area.push(sublocality);

    let address = {
      premise: premise ? premise : name,
      area: join(area, " - "),
      city: locality,
      country: country,
    };

    return { address, location: { lat: location.lat(), lng: location.lng() } };
  }
}
const GoogleMapService = new GoogleMap();
export default GoogleMapService;
