import config from '@root/config';
import TokenService from './token';
import LocaleService from './locale';
import RequestService from './request';
import {useState, useEffect} from 'react';
import {isUndefined, isNull} from 'lodash';
import {isValidString} from '@root/libs/utils';
import {map as rxmap, switchMap} from 'rxjs/operators';
class Auth {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get isAuth() {
    return isValidString(TokenService.token);
  }

  get $isAuth() {
    return TokenService.$token.pipe(rxmap(token => isValidString(token)));
  }

  useAuth() {
    const [isAuth, setIsAuth] = useState(false);

    useEffect(() => {
      let authsub = TokenService.$token.subscribe(token => {
        setIsAuth(isValidString(token));
      });
      return () => authsub.unsubscribe();
    }, []);

    return isAuth;
  }

  resetPassword = data => {
    return RequestService.$post(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/updateresetpasswordotp`,
      {body: data},
    );
  };

  forgotPasswordOtp(mobile, recaptcha) {
    return RequestService.$post(
      `${config.base_path}${LocaleService.current}/rest/V3/customapi/customer/sendresetpasswordotp`,
      {body: {mobileNumber: mobile, recaptchaToken: recaptcha}},
    );
  }

  verifyResetPasswordOtp = data => {
    return RequestService.$post(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/varifyresetpasswordotp`,
      {body: data},
    );
  };

  otpLogin(data) {
    data['biometric'] = true;
    return RequestService.post(
      `${config.base_path}rest/V1/customapi/customer/loginwithotp`,
      {body: data},
    );
  }

  otpRegister(mobile, recaptcha) {
    return RequestService.$post(
      `${config.base_path}${LocaleService.current}/rest/V3/customapi/customer/sendregistrationotp`,
      {body: {mobileNumber: mobile, recaptchaToken: recaptcha}},
    );
  }

  register(data) {
    return RequestService.$post(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/register`,
      {body: {...data, biometric: true}},
    ).pipe(
      switchMap(() =>
        this.passwordLogin({
          mobileNumber: data.mobileNumber,
          password: data.password,
        }),
      ),
    );
  }

  passwordLogin(data) {
    data['biometric'] = true;
    data['device_id'] = 'Web'; // TODO: use device service
    return RequestService.post(
      `${config.base_path}rest/V2/customapi/customer/loginwithpassword`,
      {body: data},
    );
  }

  fetchOtp(mobile, recaptcha) {
    return RequestService.post(
      `${config.base_path}rest/V3/customapi/customer/sendloginotp`,
      {
        body: {mobileNumber: mobile, recaptchaToken: recaptcha},
      },
    );
  }

  logout() {
    TokenService.clear();
  }

  useAuth() {
    const [authorized, setAuthorized] = useState(false);

    const syncLogout = event => {
      if (event.key === 'logout') setAuthorized(false);
    };

    useEffect(() => {
      let sub = TokenService.$behaviortoken.subscribe(value => {
        let result = !isUndefined(value) && !isNull(value);
        setAuthorized(result);
      });

      window.addEventListener('storage', syncLogout);

      return () => {
        sub.unsubscribe();
        window.removeEventListener('storage', syncLogout);
        window.localStorage.removeItem('logout');
      };
    }, []);

    return authorized;
  }
}

const AuthService = new Auth();
export default AuthService;
