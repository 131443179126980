import {Subject, BehaviorSubject} from 'rxjs';

export * from '@root/constants/tracking';
class Tracking {
  constructor() {
    this.events = new Subject();
    this.pool = new BehaviorSubject({});
    if(!this.instance) this.instance = this;
    return this.instance;
  }

  setTrackingData(key, data = {}) {
    let value = this.pool.getValue();
    value[key] = data;
    this.pool.next({...value});
  }

  triggerEvent(event, data = {}) {
    this.events.next({ type: event, data: data});
  }

  get eventStream() {
    return this.events.asObservable();
  }

  get dataStream() {
    return this.pool.asObservable();
  }
}
const TrackingService = new Tracking;
export default TrackingService;