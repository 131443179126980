export const LOGIN = 'login';
export const PAGE_VIEW = 'page_view';
export const PROFILE_DATA_LOADED = 'profile_data_loaded';
export const REWARDS_DATA_LOADED = 'rewards_data_loaded';
export const OPEN_APP = 'open_app';
export const SCREEN_VIEW = 'screen_view';
export const VIEW_HERO_BANNER = 'view_hero_banner';
export const SELECT_HERO_BANNER = 'select_hero_banner';
export const VIEW_CAT_GRID = 'view_cat_grid';
export const SELECT_CAT_GRID = 'select_cat_grid';
export const SEEALL_CAT_GRID = 'see_all_cat_grid';
export const VIEW_PROMO_PRODUCTS = 'view_promo_products';
export const VIEW_PROMO_BANNER = 'view_promo_banner';
export const SELECT_PROMO_BANNER = 'select_promo_banner';
export const VIEW_REWARDS = 'view_rewards';
export const PRODUCT_DETAIL = 'product_detail';
export const VIEW_CART = 'view_cart';
export const ADD_TO_CART = 'add_to_cart';
export const CART_UPDATE = 'cart_update';
export const CART_QTY_UPDATE = 'cart_qty_update';
export const REMOVE_ITM_CART = 'remove_itm_cart';
export const REMOVE_FROM_CART = 'remove_from_cart';
export const PROCEED_TO_CHECKOUT = 'proceed_to_checkout';
export const CONFIG_PRODUCT_CHANGE = 'config_product_change';
export const PRODUCT_SEARCH = 'product_search';
export const PLACE_ORDER = 'place_order';
export const BANNER_VIEW = 'banner_view';
export const BANNER_CLICK = 'banner_click';
export const PROMOTION_VIEW = 'promotion_view';
export const PROMOTION_LIST = 'promotion_list';
export const PRODUCT_CATALOG = 'product_catalog';
export const CHECKOUT_DATA_UPDATE = 'checkout_data_update';
export const CHANGE_SHIPPING_ADDRESS = 'change_shipping_address';
export const SET_DELIVERY_ADDRESS = 'set_delivery_address';
export const SET_DELIVERY_TIMESLOT = 'set_delivery_timeslot';
export const SELECT_PAYMENT_METHOD = 'select_payment_method';
export const SELECT_SHIPPING_METHOD = 'select_shipping_method';
export const APPLY_DISCOUNT = 'apply_discount';
export const REMOVE_DISCOUNT = 'remove_discount';
export const SET_DELIVERY_INSTRUCTIONS = 'set_delivery_instructions';
export const UNSET_DELIVERY_INSTRUCTIONS = 'unset_delivery_instructions';
export const START_PLACE_ORDER = 'start_place_order';
export const CHECKOUT_SUCCESS = 'checkout_success';
export const CHECKOUT_FAILED = 'checkout_failed';
export const ADD_TO_WISHLIST = 'add_to_wishlist';
export const REGISTER = 'register';
export const LOADED_SAVED_CARDS = 'loaded_saved_cards';
export const SELECT_SAVED_CARD = 'select_saved_card';
export const TOGGLE_NEW_CARD = 'toggle_new_card';
export const CC_ACTIVATED = 'cc_activated';
export const CC_TOKENIZE = 'cc_tokenize';
export const CC_REINIT = 'cc_reinit';
export const CC_VALIDATED = 'cc_validated';
export const CC_TOKENIZED = 'cc_tokenized';
export const COUPON_EVENT = 'coupon_event';
export const CC_TOGGLE_REMEMBER = 'cc_toggle_remember';
export const LANGUAGE_SWITCH_FROM_HOME = 'language_switch_from_home';
export const ON_BOARD_SKIP = 'on_board_skip';
export const ON_BOARD_LOGIN = 'on_board_login';
export const ON_BOARD_REGISTER = 'on_board_register';
export const ON_BOARD_LANGUAGE_SELECT = 'on_board_language_select';