import { isNull } from "lodash";
import config from "@root/config";
import LocaleService from "./locale";
import { tap } from "rxjs/operators";
import RequestService from "./request";
import { BehaviorSubject } from "rxjs";
import StorageService from "./storage";

class Rewards {
  constructor() {
    this.tierData = new BehaviorSubject(null);
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get refCode() {
    return StorageService.read(config.referalKey);
  }

  set refCode(code) {
    StorageService.write(config.referalKey, code);
  }

  landingId() {
    return LocaleService.current === "en"
      ? config.rewards.cms_id_en
      : config.rewards.cms_id_ar;
  }

  getCurrentTier({fresh = false} = {}) {
    let cached = this.tierData.getValue();
    if(!isNull(cached) && !fresh) return this.tierData.asObservable();
    else return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/getTierInfo`
    ).pipe(tap(result=>this.tierData.next(result)));
  }

  getHistory() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/mine/transactions`
    );
  }

  getPendingTransactions() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/getPendingTransactions`
    );
  }

  getMyReferrals() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/getCustomerReferrals`
    );
  }

  inviteFriends(data) {
    return RequestService.$authPost(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/inviteFriends`,
      {
        body: data,
      }
    );
  }

  getReferralLink() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/rewards/mine/referralCode`
    );
  }
  getHighFive() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/high5`
    );
  }
}
const RewardsService = new Rewards();
export default RewardsService;
