import React from "react";
import Image from "next/image";
import { isFunction } from "lodash";

import styles from "./styles.module.scss";

function Illustration({ title, image, message, button, onPress }) {

  const onClickButton = () => {
    if (isFunction(onPress)) onPress();
  };

  return (
    <div className={styles.illustration}>
      <div className={styles.illustration_image}>
        <Image alt="" src={image} />
      </div>
      <h5 className={styles.illustration_title}>{title}</h5>
      {message && <p className={styles.illustration_message}>{message}</p>}
      {button && (
        <button onClick={onClickButton} className={styles.illustration_button}>
          {button}
        </button>
      )}
    </div>
  );
}

export default Illustration;
