import { from } from 'rxjs';
import { extend } from 'lodash';
import { create } from "apisauce";
import TokenService from "./token";
import ParserService from "./parser";
import { map as rxmap } from 'rxjs/operators';

class Request {
  constructor() {
    this.api = create({
      headers: {
        "node":"magento",
        // 'Accept': 'application/json',
        // 'Content-Type': 'application/json',
        // 'Cache-Control': 'no-cache',
      },
    });

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  _with_auth_headers(headers) {
    return extend({}, {Authorization: `Bearer ${TokenService.token}`}, headers);
  }

  _responseHandler(response) {
    let parsed = ParserService.parseResponse(response);
    if(response.ok) return parsed;
    else throw parsed;
  }

  async get(url, { params = {}, headers = {} } = {}) {
    let response = await this.api.get(url, params, { headers });
    return ParserService.parseResponse(response);
  }

  $get(url, { params = {}, headers = {} } = {}) {
    return from(this.api.get(url, params, { headers })).pipe(
      rxmap(response=>this._responseHandler(response))
    )
  }

  async post(url, { body = {}, headers = {} } = {}) {
    let response = await this.api.post(url, body, { headers });
    return ParserService.parseResponse(response);
  }

  $post(url, { body = {}, headers = {} } = {}) {
    return from(this.api.post(url, body, { headers })).pipe(
      rxmap(response=>this._responseHandler(response))
    )
  }

  async put(url, { body = {}, headers = {} } = {}) {
    let response = await this.api.put(url, body, { headers });
    return ParserService.parseResponse(response);
  }

  $put(url, { body = {}, headers = {} } = {}) {
    return from(this.api.put(url, body, { headers })).pipe(
      rxmap(response=>this._responseHandler(response))
    )
  }

  async delete(url, { params = {}, headers = {} } = {}) {
    let response = await this.api.delete(url, params, { headers });
    return ParserService.parseResponse(response);
  }

  $delete(url, { params = {}, headers = {} } = {}) {
    return from(this.api.delete(url, params, { headers })).pipe(
      rxmap(response=>this._responseHandler(response))
    )
  }

  async authGet(url, { params = {}, headers = {} } = {}) {
    let response = await this.api.get(url, params, { headers: this._with_auth_headers(headers) });
    return ParserService.parseResponse(response);
  }

  $authGet(url, { params = {}, headers = {} } = {}) {
    return this.$get(url, {params, headers: this._with_auth_headers(headers)})
  }

  async authPost(url, { body = {}, headers = {} } = {}) {
    let response = await this.api.post(url, body, { headers: this._with_auth_headers(headers) });
    return ParserService.parseResponse(response);
  }

  $authPost(url, { body = {}, headers = {} } = {}) {
    return this.$post(url, {body, headers: this._with_auth_headers(headers)});
  }

  async authPut(url, { body = {}, headers = {} } = {}) {
    let response = await this.api.put(url, body, { headers: this._with_auth_headers(headers) });
    return ParserService.parseResponse(response);
  }

  $authPut(url, { body = {}, headers = {} } = {}) {
    return this.$put(url, {body, headers: this._with_auth_headers(headers)});
  }

  async authDelete(url, { params = {}, headers = {} } = {}) {
    let response = await this.api.delete(url, params, { headers: this._with_auth_headers(headers) });
    return ParserService.parseResponse(response);
  }

  $authDelete(url, { body = {}, headers = {} } = {}) {
    return this.$delete(url, {body, headers: this._with_auth_headers(headers)});
  }
}
const RequestService = new Request();
export default RequestService;
