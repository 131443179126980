import config from '@root/config';
import LocaleService from './locale';
import RequestService from './request';
import {map as rxmap, tap} from 'rxjs/operators';
import {
  get,
  map as _map,
  extend,
  isNull,
  isUndefined,
  split,
  find,
  forEach,
  replace,
} from 'lodash';
import EventsService, {ADDRESSES_MODIFIED} from './events';

class Address {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  pickAddress(list, cityId, regionId) {
    let picked = list[0];
    if (cityId && regionId) {
      picked = find(list, {city_id: `${cityId}`, region_id: regionId});
    } else {
      let defaultAddress = find(list, {default_shipping: true});
      if (defaultAddress) picked = defaultAddress;
    }
    return picked;
  }

  parseAddress(addr_str) {
    if (isUndefined(addr_str) || isNull(addr_str) || addr_str === '') return;

    let result = {};
    let chips = split(addr_str, ', ');
    forEach(chips, chip => {
      let keyvalue = split(chip, ': ');
      result[keyvalue[0]] = keyvalue[1];
    });
    return result;
  }

  addAddress(data) {
    return RequestService.$authPost(
      `${config.base_path}rest/V1/customapi/customer/addresses/add`,
      {body: data},
    ).pipe(tap(() => EventsService.trigger({type: ADDRESSES_MODIFIED})));
  }

  updateAddress(id, address) {
    let tosend = extend({}, address, {address_id: id});
    return RequestService.$authPut(
      `${config.base_path}rest/V1/customapi/customer/addresses/update`,
      {body: tosend},
    ).pipe(tap(() => EventsService.trigger({type: ADDRESSES_MODIFIED})));
  }

  deleteAddress(addressId) {
    return RequestService.$authPost(
      `${config.base_path}rest/V1/customapi/customer/addresses/deletebyid`,
      {body: {addressId}},
    ).pipe(tap(() => EventsService.trigger({type: ADDRESSES_MODIFIED})));
  }

  addressDetail(id) {
    return this.fetchAddresses().pipe(
      rxmap(addresses => find(addresses, address => address.id == id)),
    );
  }

  fetchAddresses() {
    return RequestService.$authGet(
      `${config.base_path}${LocaleService.current}/rest/V1/customapi/customer/addresses/getalladdress`,
    ).pipe(rxmap(result => get(result, ['data'], [])));
  }

  stringifyLocation(loc_obj) {
    let lat = get(loc_obj, ['lat'], null);
    let long = get(loc_obj, ['lng'], null);
    if (isNull(lat) || isNull(long)) return;
    return `${lat},${long}`;
  }

  parseLocation(loc_str) {
    if (isUndefined(loc_str) || isNull(loc_str) || loc_str === '') return;

    let splited = split(loc_str, ',');
    let latitude = get(splited, ['0'], null);
    let longitude = get(splited, ['1'], null);
    if (isNull(latitude) || isNull(longitude)) return;
    return {lat: latitude, lng: longitude};
  }

  stringifyAddress(addr_obj) {
    if (isUndefined(addr_obj) || isNull(addr_obj) || addr_obj === '') return;

    let result = [];
    for (const property in addr_obj) {
      result.push(`${property}: ${addr_obj[property]}`);
    }
    return result.join(', ');
  }

  composeMapData(addr_data) {
    let attributes = get(addr_data, ['custom_attributes'], []);
    let latlng = find(attributes, {attribute_code: 'latlng'});
    let addr = find(attributes, {attribute_code: 'additional_address'});
    let img = find(attributes, {attribute_code: 'address_image'});
    let latlng_str = get(latlng, ['value'], '');
    let addr_str = get(addr, ['value'], '');
    let location = this.parseLocation(latlng_str);
    let address = this.parseAddress(addr_str);
    // let image = `${EnvService.envValue.media_basemedia_base}${get(
    //   img,
    //   ['value'],
    //   '',
    // )}`;
    return {location, address, image: null};
  }

  composeFormData(address) {
    if (isUndefined(address)) return {};
    let region_id = get(address, ['region_id']);
    let street1 = get(address, ['street', '0']);
    let street2 = get(address, ['street', '1']);
    let street3 = get(address, ['street', '2']);
    let defaddr = get(address, ['default_shipping']);
    let telephone = replace(
      get(address, ['telephone']),
      config.country_code,
      '',
    );

    return extend({}, address, {
      street1,
      street2,
      street3,
      telephone,
      default: defaddr,
      region_id: `${region_id}`,
    });
  }

  maskData(map_data, form_data) {
    let address = get(map_data, ['address'], null);
    let location = get(map_data, ['location'], null);
    let latlng = this.stringifyLocation(location);
    let addrstr = this.stringifyAddress(address);
    let default_shipping = get(form_data, ['default_shipping'], 0);
    let default_billing = get(form_data, ['default_billing'], 0);

    return extend({}, form_data, {
      latlng: latlng,
      postcode: '0000',
      telephone: form_data.telephone,
      country_id: config.country_code,
      additional_address: addrstr,
      is_default_billing: default_billing,
      is_default_shipping: default_shipping,
    });
  }
}
const AddressService = new Address();
export default AddressService;
