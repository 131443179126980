import {extend} from 'lodash';
let basic = require('./base.json');
let staging = require('./staging.json');
let test = require('./test.json');
let dev = require('./dev.json');
let alpha = require('./alpha.json');
let beta = require('./beta.json');
let prod = require('./prod.json');

let environment = prod;
if (process.env.environment === 'test') environment = test;
if (process.env.environment === 'dev') environment = dev;
if (process.env.environment === 'staging') environment = staging;
if (process.env.environment === 'alpha') environment = alpha;
if (process.env.environment === 'beta') environment = beta;
export default extend({}, basic, environment);