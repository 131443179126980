import {useTranslation} from 'next-i18next';

export const lang = {value: 'en'};
class Locale {
  constructor() {
    if (!this.instance) this.instance = this;
    return this.instance;
  }

  set current(value) {
    lang.value = value;
  }

  get current() {
    return lang.value;
  }

  get isArabic() {
    return lang.value === 'ar';
  }

  useLocale() {
    return useTranslation('common');
  }
}

const LocaleService = new Locale();
export default LocaleService;
