import TrackingService, {
  LOGIN,
  REGISTER,
  PAGE_VIEW,
  ADD_TO_CART,
  PRODUCT_DETAIL,
  PRODUCT_CATALOG,
  CHECKOUT_SUCCESS,
  REMOVE_FROM_CART,
  START_PLACE_ORDER,
  VIEW_PROMO_PRODUCTS,
  SELECT_SHIPPING_METHOD,
} from "@root/services/tracking";
// import LoggerService from "@root/services/logger";
import { map as rxmap, switchMap } from "rxjs/operators";
import { get, map, isNull, forEach, isUndefined, toString, filter, find, findKey } from "lodash";

class Google {
  constructor() {
    let datastream = switchMap((event) => TrackingService.dataStream.pipe(
      rxmap((pool) => ({ pool, event }))
    ));
    TrackingService.eventStream
      .pipe(datastream)
      .subscribe((eventpool) => {
        switch (eventpool.event.type) {
          case LOGIN:
            this.userLogin(eventpool);
            break;
          case REGISTER:
            this.userRegister(eventpool);
            break;
          case PAGE_VIEW:
            this.pageView(eventpool);
            break;
          case PRODUCT_CATALOG:
            this.productListView(eventpool);
            break;
          case PRODUCT_DETAIL:
            this.productDetail(eventpool);
            break;
          case VIEW_PROMO_PRODUCTS:
            this.viewPromoProducts(eventpool);
            break;
          case ADD_TO_CART:
            this.addToCart(eventpool);
            break;
          case REMOVE_FROM_CART:
            this.removeFromCart(eventpool);
            break;
          case SELECT_SHIPPING_METHOD:
            this.shippingmethod(eventpool);
            break;
          case START_PLACE_ORDER:
            this.startPlaceOrder(eventpool);
            break;
          case CHECKOUT_SUCCESS:
            this.checkoutSuccess(eventpool);
            break;
        }
      });

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  init(data) {
    window.dataLayer.push({
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
    });
    window.dataLayer.push({
      event: "config",
      page_path: data.path,
    });
  }

  _strOnly(value) {
    if (isUndefined(value) || isNull(value) || !value) return "";
    else return toString(value);
  }

  _floatOnly(value) {
    if (isUndefined(value) || isNull(value) || !value) return 0.0;
    else return parseFloat(value);
  }

  pageView(event) {
    try {
      let tosend = {
        event: 'page_view',
        page_path: data.path,
      };
      window.dataLayer.push(tosend);
    } catch (error) {}
  }

  productListView(event) {
    try {
      let products = [];
      let catid = get(event, ['event', 'data', 'catid']);
      let items = get(event, ['event', 'data', 'items'], {});
      let catname = findKey(items, item => (item?.properties?.id === catid));
      let found = find(items, item => (item?.properties?.id === catid));
      forEach(found.child_categories, item => products.push(...item.products));

      let tosend = {
        event: 'productImpressions',
        ecommerce: {
          currencyCode: 'AED',
          impressions: map(products, (product, index) => {
            let itemId = get(product, ['id']);
            let itemName = get(product, ['name']);
            let price = get(product, ['price_raw']);
            let itemVairant = get(product, ['product_option']);
            let specialPrice = get(product, ['special_price_raw']);
            let itemPrice = specialPrice ? specialPrice : price;
            return {
              id: itemId,
              name: itemName,
              price: itemPrice,
              brand: catid,
              category: catname,
              variant: itemVairant,
              list: catname,
              position: index
            }
          })
        }
      };
      window.dataLayer.push(tosend);
    }
    catch(error) {
      // LoggerService.logError('GTM Product Impressions failed!', error);
    }
  }

  viewPromoProducts(event) {
    try {
      let listId = get(event, ['event', 'data', 'link']);
      let listName = get(event, ['event', 'data', 'label']);
      let listItems = get(event, ['event', 'data', 'content'], []);

      let tosend = {
        event: 'productImpressions',
        ecommerce: {
          currencyCode: 'AED',
          impressions: map(listItems, (item, index) => {
            let itemId = get(item, ['id']);
            let itemName = get(item, ['name']);
            let price = get(item, ['price_raw']);
            let itemVairant = get(item, ['product_option']);
            let specialPrice = get(item, ['special_price_raw']);
            let itemPrice = specialPrice ? specialPrice : price;
            return {
              id: itemId,
              name: itemName,
              price: itemPrice,
              brand: listId,
              category: listName,
              variant: itemVairant,
              list: listName,
              position: index
            }
          })
        }
      };

      window.dataLayer.push(tosend);
    }
    catch(error) {
      // LoggerService.logError('GTM Product Impressions failed!', error);
    }
  }

  userLogin(event) {
    try {
      let user = get(event, ['event', 'data'], {});
      let id = get(user, ['customer_id']);
      let firstname = get(user, ['firstname'], '');
      let lastname = get(user, ['lastname'], '');
      let email = get(user, ['email'], '');
      let group = get(user, ['group']);
      let phone = get(user, ['mobilenumber'], '');

      let tosend = {
        Name: `${firstname} ${lastname}`,
        Identity: id,
        Email: email,
        Phone: phone,
      };
      if (group) tosend['Group'] = group;

      window.dataLayer.push({
        event: "login",
        ...tosend
      });
    }
    catch(error) {
      // LoggerService.logError('GTM Login tag issue', error);
    }
  }

  userRegister(event) {
    try {
      let user = get(event, ['event', 'data'], {});
      let id = get(user, ['customer_id']);
      let firstname = get(user, ['firstname'], '');
      let lastname = get(user, ['lastname'], '');
      let email = get(user, ['email'], '');
      let group = get(user, ['group']);
      let phone = get(user, ['mobilenumber'], '');

      let tosend = {
        Name: `${firstname} ${lastname}`,
        Identity: id,
        Email: email,
        Phone: phone,
      };
      if (group) tosend['Group'] = group;

      window.dataLayer.push({
        event: "register",
        ...tosend
      });
    }
    catch(error) {
      // LoggerService.logError('GTM Register tag issue', error);
    }
  }

  addToCart(event) {
    try {
      let catname = get(event, ["event", "data", "label"], {});
      let product = get(event, ["event", "data", "product"], {});
      let cartItems = get(event, ["event", "data", "cartItem", "items"], []);
      let cartItem = find(cartItems, item => (item.product_id == product.id));

      if (isNull(product) || isNull(cartItems)) return;

      let prodid = get(product, ["id"]);
      let prodname = get(product, ["name"]);
      let price = get(product, ["price_raw"]);
      let prodqty = get(cartItem, ["qty"]);
      let prodvariant = get(product, ['product_option']);
      let specialPrice = get(product, ["special_price_raw"]);
      let itemPrice = specialPrice ? specialPrice : price;

      let tosend = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {step: 1},
            products: [{
              id: prodid,
              name: prodname,
              price: itemPrice,
              brand: prodid,
              category: catname,
              variant: prodvariant,
              quantity: prodqty
            }]
          }
        },
      }

      window.dataLayer.push(tosend);
    } catch (error) {
      // LoggerService.logError('GTM Add Cart tag issue', error);
    }
  }

  removeFromCart(event) {
    try {
      let quote = get(event, ["pool", "values", "quote"], null);
      let cart = get(event, ["pool", "values", "cart"], {});
      let product = get(event, ["event", "data", "product"], {});
      let cartItem = get(event, ["event", "data", "cartItem"], {});

      if (isNull(product) || isNull(cartItem)) return;

      let itemId = get(product, ["id"]);
      let itemName = get(cartItem, ["name"]);
      let itemPrice = get(product, ["price"]);
      let itemQuantity = get(cartItem, ["qty"]);
      let cartValue = get(cart, ["grand_total"]);
      let listName = get(product, ["listname"]);
      let itemVairant = get(product, ["product_option"]);

      let tosend = {
        items: [
          {
            price: itemPrice,
            quantity: itemQuantity,
            item_variant: itemVairant,
            item_id: this._strOnly(itemId),
            item_name: this._strOnly(itemName),
            item_list_id: this._strOnly(quote),
            item_list_name: this._strOnly(listName),
          },
        ],
        value: this._floatOnly(cartValue),
        currency: "AED",
      };

      window.dataLayer.push({
        event: 'removeFromCart',
        ...tosend
      })
    } catch (error) {
      // LoggerService.logError('GTM Remove Cart tag issue', error);
    }
  }

  productDetail(event) {
    try {
      let itemId = get(event, ['event', 'data', 'id']);
      let price = get(event, ['event', 'data', 'price_raw']);
      let specialPrice = get(event, ['event', 'data', 'special_price_raw']);
      let itemName = get(event, ['event', 'data', 'name']);
      let itemVariant = get(event, ['event', 'data', 'product_option']);
      let itemPrice = specialPrice ? specialPrice : price;

      let tosend = {
        event: 'productDetail',
        ecommerce: {
          detail: {
            actionField: {list: ''},
            products: [{
              id: itemId,
              name: itemName,
              price: itemPrice,
              brand: '',
              category: '',
              variant: itemVariant
            }]
          }
        },
      }

      window.dataLayer.push(tosend);
    } catch (error) {
      // LoggerService.logError('GTM Product Detail tag issue', error);
    }
  }

  shippingmethod(event) {
    try {
      let chkoutData = get(event, ['event', 'data'], {});
      let items = get(chkoutData, ['items'], []);

      let tosend = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {step: 2},
            products: map(items, item => {
              return {
                id: item.id,
                name: item.name,
                price: item.price,
                brand: '',
                category: '',
                variant: item.sku,
                quantity: item.qty
              }
            })
          }
        }
      };

      window.dataLayer.push(tosend);
    }
    catch(error) {}
  }

  startPlaceOrder(event) {
    try {
      let userdata = get(event, ['pool', 'values', 'user'], {});
      let cartdata = get(event, ['pool', 'values', 'cart'], {});
      let quoteid = get(event, ['pool', 'values', 'quote'], null);
      let chktdata = get(event, ['event', 'data'], {});
      let items = get(chktdata, ['items'], []);

      let tosend = {
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {step: 3},
            products: map(items, item => {

              return {
                name: item?.name,
                price: item?.price,
                id: item?.product_id,
                brand: '',
                category: '',
                variant: item?.sku,
                quantity: item?.qty,
                dimension2: ''
              }
            })
          }
        }
      }
      window.dataLayer.push(tosend);
    }
    catch(error) {}
  }

  checkoutSuccess(event) {
    try {
      let userdata = get(event, ['pool', 'values', 'user'], {});
      let orderdata = get(event, ['event', 'data'], {});
      let items = get(orderdata, ['items'], []);

      let userid = get(userdata, ['id']);
      let taxamount = get(orderdata, ['tax_amount']);
      let grandtotal = get(orderdata, ['grand_total']);
      let incrementid = get(orderdata, ['increment_id']);
      let shipping = get(orderdata, ['shipping_incl_tax']);
      let products = map(items, item => ({
        category: '',
        dimension2: '',
        brand: 'barakat',
        name: get(item, ['name']),
        variant: get(item, ['sku']),
        id: get(item, ['product_id']),
        price: get(item, ['price_incl_tax']),
        quantity: get(item, ['qty_ordered']),
      }));

      let tosend = {
        userid: userid,
        event: 'purchase',
        ecommerce: {
          // data for GA4
          transaction_id: incrementid,
          value: grandtotal,
          tax: taxamount,
          shipping: shipping,
          currency: "AED",
          coupon: "",
          items: products,
          // data for GA4
          
          purchase: {
            actionField: {
              tax: taxamount,
              id: incrementid,
              shipping: shipping,
              revenue: grandtotal,
              affiliation: 'barakat',
              coupon: ''
            },
            products: products
          }
        }
      };

      window.dataLayer.push(tosend);
    } catch (error) {
      // LoggerService.logError('GTM Checkout tag issue', error);
    }
  }
}
const GoogleTracker = new Google();
export default GoogleTracker;
