import config from "@root/config";
import { BehaviorSubject } from "rxjs";
import { isValidString } from '@root/libs/utils';
import EventsService, {LOGIN, LOGOUT} from "./events";
import { getCookie, setCookie, deleteCookie } from "cookies-next";
class Token {
  constructor() {
    let cookie = getCookie(config.tokenKey);
    this.$behaviortoken = new BehaviorSubject(cookie ? cookie : null);

    if (!this.instance) this.instance = this;
    return this.instance;
  }

  get $token() {
    // to trigger auth events
    return this.$behaviortoken.asObservable();
  }

  get token() {
    // serve fresh straight out of oven
    return getCookie(config.tokenKey);
  }

  set(value) {
    if(!isValidString(value)) throw new Error("Invalid token value");
    setCookie(config.tokenKey, value, {maxAge: '31536000'}); // set for one year
    this.$behaviortoken.next(value);
    EventsService.trigger({type: LOGIN});
  }

  clear() {
    this.$behaviortoken.next(null);
    deleteCookie(config.tokenKey);
    EventsService.trigger({type: LOGOUT});
    window.localStorage.setItem("logout", Date.now());
  }
}
const TokenService = new Token();
export default TokenService;
