import LoggerService from './logger';

class Storage {
  constructor () {
    if(!this.instance) this.instance = this;
    return this.instance;
  }

  write(key, data) {
    try {
      window.localStorage.setItem(key, data);
    }
    catch (error) {
      LoggerService.logError('Local Storage write error!', error);
    }
  }

  read(key) {
    try {
      return window.localStorage.getItem(key);
    }
    catch (error) {
      LoggerService.logError('Local Storage read error!', error);
    }
  }

  remove(key) {
    try {
      window.localStorage.removeItem(key);
    }
    catch (error) {
      LoggerService.logError('Local Storage remove error!', error);
    }
  }
}

const StorageService = new Storage;
export default StorageService;