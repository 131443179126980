export const AUTH_CANCELLED = 'auth_cancelled';
export const AUTH_SUCCESS = 'auth_success';

export const LOGIN = 'login';
export const LOGOUT = 'logout';

export const ADDRESSES_MODIFIED = 'addresses_modified';

export const LOCATION_CHANGE = 'location_change';
export const PICK_LOCATION = 'pick_location';
export const SESSION_TIMEOUT = 'session_timeout';

export const CHKT_TIMESLOT_CHANGE = 'checkout_timeslot_change';
export const COUPON_CHANGE = 'coupon_change';
export const ORDER_SUCCESS = 'order_success';
export const ORDER_FAILURE = 'order_failure';
export const BARAKAS_CHANGE = 'BARAKAS_CHANGE';
export const STORECREDIT_CHANGE = 'storecredit_change';
export const ATTACH_SHIPPING = 'attach_shipping';

export const CARD_REINIT = 'card_reinit';
export const PLACE_ORDER = 'place_order';
export const METHOD_SELECT = 'method_select';
export const USE_SAVED_CARD = 'use_saved_card';
export const CARD_VALIDITY = 'card_validity';
export const SAVED_CARD_TOKENIZED = 'saved_card_tokenized';
export const NEW_CARD_TOKENIZED = 'new_card_tokenized';
export const PLACE_ORDER_NEW_CC = 'place_order_new_cc';
export const PLACE_ORDER_SAVED_CC = 'place_order_saved_cc';
export const RESET_CART = 'reset_cart';

export const SET_PAYMENT_METHOD = 'payment_method';
export const FALSH_SALES_ADD_TO_CART = 'flash_sales_addToCart';
